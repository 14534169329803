import React, { Component } from "react"
import Countdown from "./Countdown"
import { Navbar, Container, Col, Row } from "react-bootstrap"

class App extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="light" fixed="top" className="purple">
        <Container fluid>
          <Row style={{margin:"auto"}}>
            <Col lg={12} xs={12}>
              <div className="text-App">
                <p>SEGERA PESAN SEBELUM</p>
              </div>
            </Col>
            <Col lg={12} xs={12}>
              <div className="App">
                <Countdown date={`October 31, 2023 23:59:59`} />
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    )
  }
}

export default App
