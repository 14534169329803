import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { CheckCircle } from "react-bootstrap-icons"
import { FaHandPointRight } from "react-icons/fa"
import { Accordion, Card, Button } from "react-bootstrap"
import AnchorLink from "react-anchor-link-smooth-scroll"
import VideoModalBs from "../components/section/modalVideo/video"
import VideoModalBsTwo from "../components/section/modalVideo/videoTwo"
import ProgressBar from "react-bootstrap/ProgressBar"
// import CountUp from "react-countup"
import App from "./Apppromo"
import { FaWhatsapp } from "react-icons/fa"

function Special() {
  const [urlParams, setUrlParams] = useState("")
  const [detectUrlParms, setDetectUrlParms] = useState(null)
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const dataParams = urlParams.get("fbclid")
      if (urlParams) {
        setUrlParams(urlParams)
        setDetectUrlParms(dataParams)
      }
    }
  }, [])
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>buku Happy Love Guide</title>
        </Helmet>
      </div>
      <App />
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="wrappe-headline-top-text"
                style={{
                  fontSize: "20px",
                  marginBottom: "0px",
                  marginTop: "150px",
                }}
              >
                <p className="text-white">
                  <b>PEMESANAN HAPPY LOVE GUIDE KEMBALI DIBUKA!</b>
                </p>
                <p className="text-white">
                  <b>Sudah TerjualSudah Terjual 4.205+ Copy Copy</b>
                </p>
                <h3 className="text-white">
                  <span style={{ background: "#594b7b" }}>
                    Penting: Untuk Kamu Yang Ingin Bertemu Pria Yang Tepat Tapi
                    Gak Tau Gimana Cara Mulainya..
                  </span>
                </h3>
                <h2 className="text-white" style={{ lineHeight: "1.5" }}>
                  <b>
                    PANDUAN{" "}
                    <span style={{ background: "#594b7b" }}>
                      MENEMUKAN PRIA YANG TEPAT
                    </span>{" "}
                    DENGAN CARA MENYENANGKAN{" "}
                    <span>
                      <span style={{ background: "#594b7b" }}>
                        TANPA HARUS MENGEJAR ATAU MENURUNKAN STANDAR
                      </span>
                    </span>
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <VideoModalBsTwo />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testimoni iis (1) (1) (1).jpg"
                      alt="testimoni1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/photo_6273834496965064337_x (1).jpg"
                      alt="testimoni2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/IMG_4905 (1).jpg"
                      alt="testimoni3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/6 (1).jpg"
                      alt="testimoni4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/5 (1).jpg"
                      alt="testimoni5"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="card my-2">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid img-testimoni-book"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/2 (1).jpg"
                      alt="testimoni6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-button-join-no-height d-flex justify-content-center">
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

        <div className="leaf-accens-left">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-left.png"
            className="img-fluid"
            alt="leaf accens left"
          />
        </div>
        <div className="leaf-accens-right">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-right.png"
            className="img-fluid"
            alt="leaf accens right"
          />
        </div>
      </div>

      <div className="container-fluid bg-full-overlay-jose">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="wrappe-img-jose-circle"></div>
            </div>
            <div className="col-lg-12">
              <div className="wrappe-wording-caption">
                <h2 className="text-white">
                  <b>Jose Aditya, Love Life Coach</b>
                </h2>
                <h4>Pelatih Percintaan Wanita #1 di Indonesia.</h4>
                <p>
                  Awalnya Jose ingin menikah namun “terhalang” kondisi kakak
                  perempuan yang berusia 30+ tapi belum kunjung punya calon.
                </p>
                <p>
                  Dengan niat membantu sang kakak perempuan untuk menggapai
                  cinta, Jose Aditya melakukan riset mendalam mengenai kehidupan
                  cinta wanita dari aspek psikologis, biologis, dan
                  neuroscience.
                </p>
                <p>
                  Selama kurang lebih 6 bulan intens mengikuti seminar,
                  workshop, training dan juga coaching dari Jose, sang kakak
                  akhirnya berhasil menemukan pria idaman.
                </p>
                <div className="thumbnail-prestasi">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/1632285008980.jpg"
                    className="img-fluid"
                    alt="thumbnail prestasi"
                  />
                </div>
                <p>
                  Setelah berhasil membantu sang kakak, ia sadar jutaan wanita
                  juga punya masalah serupa. Impiannya kini adalah membantu 1
                  juta wanita untuk bahagia dalam kehidupan cinta mereka.
                </p>
                <p>
                  Karena ia percaya, wanita yang bahagia bisa membuat dunia
                  lebih indah. Dimulai dari kamu..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="wrappe-headline-top-text"
                style={{ paddingTop: "50px" }}
              >
                <h2 className="text-white">
                  <b>
                    PANDUAN MENEMUKAN PRIA YANG TEPAT DENGAN CARA MENYENANGKAN
                    TANPA HARUS MENGEJAR ATAU MENURUNKAN STANDAR
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="thumbnail-images-4 d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/section-4.jpg"
                  alt="product hlg"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="wrappe-caption-hlg">
                    <p>Hai Ladies,</p>
                    <p>
                      Untuk kamu wanita single dan{" "}
                      <b>ingin bertemu pria yang tepat</b>
                    </p>
                    <p>
                      Dan kamu wanita yang{" "}
                      <b>ingin punya kehidupan romansa bahagia</b>
                    </p>
                    <p>Itu semua bisa terjadi dengan buku ini.</p>
                    <p>
                      Kebanyakan buku romansa cuma membahas teknis dan cara
                      instan yang bikin wanita gak nyaman mempraktekkannya.
                    </p>
                    <p>
                      Perkenalkan <b> Buku Happy Love Guide </b>
                    </p>
                    <p>
                      buku ini merangkum semua mindset, strategi dan langkah
                      yang bisa mengantarkan kamu mencintai diri sendiri,
                      menciptakan kebahagiaan sejak single hingga bertemu pria
                      yang tepat tanpa harus ngejar atau menurunkan standar.
                    </p>
                    <p>
                      Membaca habis buku ini membuatmu yang awalnya "buta" jadi
                      benar-benar tahu apa yang perlu kamu lakukan untuk bertemu
                      pria yang tepat dan menciptakan hubungan penuh
                      kebahagiaan.
                    </p>
                    <p>
                      <span style={{ background: "yellow", color: "black" }}>
                        Buku ini <b>tidak dijual di toko buku manapun.</b>
                      </span>
                    </p>
                    <p>
                      Ditulis langsung oleh Jose Aditya, Love Life Coach untuk
                      wanita nomor 1 di Indonesia.
                    </p>
                    <p>
                      Berdasarkan 8 tahun pengalaman membantu ratusan klien dan
                      ribuan member. Membongkar strategi romansa yang efektif,
                      minim drama dan cocok untuk wanita Indonesia.
                    </p>
                    <p>
                      <b>Dengan formula yang terbukti efektif.</b>
                      <br />
                      <b>KHUSUS untuk KAMU</b> yang capek berjuang sendirian dan
                      ingin berhasil di kehidupan romansa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="wrappe-headline-top-text"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <h2 className="text-white">
                  <b>SIAPA YANG MEMBUTUHKAN “PANDUAN” INI?</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-50">
                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu wanita yang{" "}
                        <b>
                          ingin bertemu pria yang tepat tapi gak tau gimana cara
                          mulainya?
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu wanita usia 30+ dan sudah{" "}
                        <b>dikejar deadline menikah</b> oleh keluarga?
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu percaya kalau jodoh itu sudah disiapkan dan
                        gak perlu diusahakan? Tapi mulai lelah menanti..
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu merasa cantik, sudah punya gebetan tapi gak
                        tau{" "}
                        <b>
                          cara bikin dia jatuh cinta dan punya perasaaan sama
                          kamu?
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu percaya jika jumlah pria lebih sedikit
                        daripada wanita sehingga kamu{" "}
                        <b>kesulitan berkenalan dengan pria potensial</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu <b>merasa pria idamanmu mulai langka?</b>{" "}
                        Kalaupun ada kebanyakan mereka sudah taken.
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu ingin tahu{" "}
                        <b>
                          rahasia wanita yang memilih tapi pria yang mengejar?
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu wanita yang{" "}
                        <b>workaholic parah sehingga kamu kehabisan energi</b>{" "}
                        untuk mengenal pria baru?
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu sudah punya calonnya tapi sayangnya{" "}
                        <b>
                          belum mapan, beda agama atau gak direstui orangtua
                        </b>{" "}
                        dan kamu bingung gimana solusinya
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give">
                        Apakah kamu kebingungan karena kehidupan{" "}
                        <b>romansa yang penuh derita?</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-button-join-no-height mrgn-bottom-50 d-flex justify-content-center">
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

        <div className="leaf-accens-left">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-left.png"
            className="img-fluid"
            alt="leaf accens left"
          />
        </div>
        <div className="leaf-accens-right">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-right.png"
            className="img-fluid"
            alt="leaf accens right"
          />
        </div>
      </div>

      <div className="container-fluid bg-white mrgn-top-220 padding-bottom-215">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-drak">
                  <b>
                    INILAH YANG AKAN KAMU DAPATKAN DARI BELI <br />
                    BUKU HAPPY LOVE GUIDE
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="images-thumbnail-book-hlg d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/PRODUK BUKU HLG.png"
                  alt="product hlg"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-50">
                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Punya panduan yang jelas</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Kamu gak perlu bingung lagi harus mulai dari mana karena
                        kamu bisa dapatkan langkah demi langkah dari single
                        hingga bertemu pria yang tepat
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Punya mindset yang tepat</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Untuk memulai, menjalani dan menciptakan hubungan yang
                        sehat dan bahagia
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Skill tanam benih cinta fisik, emosional & mental</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Apa yang perlu kamu lakukan agar dia bisa tertarik
                        secara fisik, emosional dan mental!
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Prinsip TERPENTING dalam memilih pria yang tepat</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Agar memudahkan memilih diantara beberapa yang mendekat
                        dan tidak berlarut-larut dalam proses PDKT yang menguras
                        waktu, emosi, dan uang.
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>11 Pertanyaan untuk mengenal siapa diri kamu</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Karena sebelum bisa tahu siapa pria yang tepat untukmu,
                        kamu perlu kenal dengan diri sendiri dulu.
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Kuasai teknik{" "}
                        <b>Wanita yang Memilih, Pria yang Mengejar"</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Sehingga kamu gak perlu terlihat agresif dan needy
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Tau hal "seksi" yang bisa memikat pria</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Cara membuat pria melting dan salting tanpa
                        mengeksploitasi sisi seksualitas sama sekali. Psst..
                        Semua wanita cerdas menggunakan ini loh!
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Skill validasi cowok!</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Cara memastikan apakah dia pria yang serius dan
                        kompatibel luar-dalam untuk jadi pasangan kamu hingga
                        ajal menjemput.
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Teknik kenalan di online dating</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Bagaimana cara menata profile kamu di online dating agar
                        profile dan interaksi kamu mengundang cowok-cowok keren!
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        <b>Studi Kasus</b>
                      </p>
                      <p className="li-text-checlist-what-you-give">
                        Bagaimana jika kamu sibuk dan gak punya waktu cari
                        kenalan, bagaimana jika kamu pernah menikah, bagaimana
                        jika dia belum mapan, ada sifatnya yang kamu gak suka,
                        dst.
                      </p>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-lg-12">
                      <p className="li-text-checlist-what-you-give">
                        ..dan masih banyak lagi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-button-join-no-height d-flex justify-content-center">
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

        <div className="leaf-accens-left">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-left.png"
            className="img-fluid"
            alt="leaf accens left"
          />
        </div>
        <div className="leaf-accens-right">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-right.png"
            className="img-fluid"
            alt="leaf accens right"
          />
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-white">
                  <b>
                    MARI SAYA BOCORKAN BEBERAPA HAL YANG AKAN KAMU DAPAT DI BUKU
                    INI
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="thumbnail-list-content d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/list-content.jpg"
                  alt="product hlg"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-50">
                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Faktanya, yang membuat kehidupan romansa jadi bahagia
                        BUKANLAH pernikahan. Tapi apa yang kita lakukan dalam
                        pernikahan tersebut karena menikah bukan solusi dari
                        permasalahanmu <b>(halaman 60)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Banyak orang yang percaya bahwa jodoh seseorang sudah
                        ditetapkan oleh Tuhan. Tapi TIDAK dalam definisi pasif.{" "}
                        <b>(halaman 75)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Cantik bukan jaminan bisa cepat mendapat pria yang
                        sesuai kriteria. Berapa banyak pria selingkuh dengan
                        wanita yang tidak lebih cantik dari istrinya?{" "}
                        <b>(halaman 83)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Menurut data BPS tahun 2018, Populasi pria masih lebih
                        banyak 1,3 juta ketimbang wanita. <b>(halaman 89)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        FOKUSLAH pada hal yang memberdayakan. Peluang itu ada,
                        peluang itu banyak. Termasuk pria luar biasa.{" "}
                        <b>(halaman 92)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Wanita tidak mengungguli pria secara fisik, melainkan
                        secara mental. Dan faktanya, mental memegang peranan
                        lebih besar ketimbang kekuatan fisik.{" "}
                        <b>(halaman 130)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Karena kegiatan Di.V.A memang didesain secara spesifik
                        untuk tidak mengganggu agenda rutin penting kamu.{" "}
                        <b>(halaman 336)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Mayoritas pria lajang memang inginnya dengan gadis juga.
                        Tapi ada kok diantara mereka (apalagi yang sudah mapan)
                        ingin mencari sosok pasangan yang lebih dewasa.{" "}
                        <b>(halaman 361)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Jika kamu bertemu pria yang belum mapan secara
                        finansial, apakah kamu boleh memilihnya? Jawabannya
                        adalah TERGANTUNG <b>(halaman 371)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="wrappe-adding-given">
                        <h4>
                          <b>Ditambah saya akan membocorkan…</b>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Cara untuk bertemu pria yang tepat dan bagaimana proses
                        melakukan validasinya <b>(halaman 165)</b>
                      </p>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div>
                      <CheckCircle className="bg-white-icon-circle" />
                    </div>
                    <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                      <p className="li-text-checlist-what-you-give mb-2">
                        Formula dan skill yang sudah terbukti berhasil{" "}
                        <b>(di setiap Bab!)</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-white">
                  <b>Penasaran? Sini Saya Kasih Intip Beberapa Isinya</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <p className="text-collapse-heading">
                        Klik untuk intip isinya…
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-1.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-2.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-3.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-4.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-5.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <p className="text-collapse-heading">
                        Klik untuk intip isinya…
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-6.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-7.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-8.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-9.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-10.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="wrappe-images-highlight">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Foto-11.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "0px" }}>
            <div className="col-lg-12">
              <div className="wrappe-button-join d-flex justify-content-center">
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text mobile-bonus-heading">
                <h2 className="text-drak">
                  <b>GRATIS BONUS</b>
                </h2>
                <h4>
                  <span style={{ background: "yellow" }}>
                     JIKA PESAN BUKUNYA SEKARANG!
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center my-3">
            <div className="col-lg-4 text-center">
              <img
                className="img-fluid"
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup Cheatsheet (1).png"
                alt="bonus"
              />
            </div>
            <div className="col-lg-7">
              <div className="description-bonus">
                <h2>BONUS PDF Cheat Sheet</h2>
                <p>
                  Rangkuman Visual Buku Happy Love Guide <br /> <br />{" "}
                  <b>Senilai 129.000</b>
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center my-3">
            <div className="col-lg-4 text-center">
              <img
                className="img-fluid"
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup LADY Skillset (1).png"
                alt="bonus"
              />
            </div>
            <div className="col-lg-7">
              <div className="description-bonus">
                <h2>BONUS PDF LADY Skillset</h2>
                <p>
                  Kumpulan skill, teknik, dan pembiasaan yang <br /> memudahkan
                  Kamu bertemu pria tepat dalam 90 hari atau kurang
                  <br /> <br />
                  <b>Senilai 199.000</b>
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center my-3">
            <div className="col-lg-4 text-center">
              <img
                className="img-fluid"
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup Love Life Blueprint (1).png"
                alt="bonus"
              />
            </div>
            <div className="col-lg-7">
              <div className="description-bonus">
                <h2>BONUS PDF Love Life Blueprint</h2>

                <p>
                  Setiap bangunan kokoh, pasti memiliki rancangan yang matang.
                  Begitu pula dengan kehidupan relationship
                  <br />
                  <br />
                  <b>Senilai 222.000</b>
                </p>
              </div>
            </div>
          </div>
          <div className="row" id="order" style={{ paddingBottom: "100px" }}>
            <div className="col-lg-12">
              <div className="pricing-plus-bonus">
                <p className="text-center">
                  <b>Harga normal :</b>{" "}
                  <b>
                    <span style={{ color: "red" }}>
                      <del>Rp 558.000</del>
                    </span>
                  </b>
                </p>
                <p className="text-center">
                  <b>Kamu cukup bayar:</b>
                </p>
                <h2>Rp 229.000</h2>
                <p className="text-center pt-2" style={{ fontSize: "18px" }}>
                  <span style={{ background: "yellow" }}>
                    HEMAT 60% s.d 30 Juni 2023
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="wrappe-button-join-no-height d-flex justify-content-center"
                style={{ marginTop: "20px" }}
              >
                {detectUrlParms === null ? (
                  <>
                    <a
                      href="https://trulavid.orderonline.id/happy-love-guide"
                      target="_blank"
                      rel="noreferrer"
                      className="oo-link"
                    >
                      <button type="button" className="btn btn-light">
                        <FaHandPointRight /> YA, SAYA MAU
                      </button>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href={
                        "https://trulavid.orderonline.id/happy-love-guide" +
                        urlParams
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="oo-link"
                    >
                      <button type="button" className="btn btn-light">
                        <FaHandPointRight /> YA, SAYA MAU
                      </button>
                    </a>
                  </>
                )}
                {/* <a
                  href="https://cart.lovecoach.id/product/happy-love-guide-book"
                  target="_blank"
                  rel="noreferrer"
                  className="oo-link"
                >
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </a> */}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text mobile-bonus-heading">
                <h2 className="text-drak">
                  <b>
                    <span
                      style={{
                        background: "red",
                        color: "white",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      STOP
                    </span>
                  </b>
                </h2>
                <h4>DAPATKAN TAMBAHAN BONUS!</h4>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <ProgressBar
                animated
                variant="success"
                now={40}
                label="40%"
                max={100}
                min={0}
              />
              <div className="row justify-content-center">
                <div className="col-lg-4 col-xs-4 col-4">
                  <div className="img-icon-progress">
                    <img
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Check 300.png"
                      className="img-fluid icon30Persen"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xs-4 col-4">
                  <div className="img-icon-progress">
                    <img
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Check 500.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xs-4 col-4">
                  <div className="img-icon-progress">
                    <img
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Check 500.png"
                      className="img-fluid"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-xs-4">
                  <Card className="text-center my-3">
                    <Card.Img
                      variant="top"
                      className="img-bonus-opt"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Ipad FS (1).png"
                    />
                    <Card.Body>
                      <Card.Title>
                        E-Course Feminine Secret Senilai Rp 299.000
                      </Card.Title>
                      <Card.Text>Untuk 300 Pembeli Pertama</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-lg-4 col-xs-4">
                  <Card className="text-center my-3">
                    <Card.Img
                      variant="top"
                      className="img-bonus-opt"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup Ipad Seni Mendekati Pria BW.png"
                    />
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        E-Course Seni Mendekati Pria Senilai Rp 497.000
                      </Card.Title>
                      <Card.Text>Untuk 500 Pembeli Pertama</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-lg-4 col-xs-4">
                  <Card className="text-center my-3">
                    <Card.Img
                      variant="top"
                      className="img-bonus-opt"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup Ipad Bahasa Pria BW.png"
                    />
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        E-Course Bahasa Pria Senilai Rp 799.000
                      </Card.Title>
                      <Card.Text>1000 Pembeli Pertama</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="leaf-accens-left">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-left.png"
                    className="img-fluid"
                    alt="leaf accens left"
                  />
                </div>
                <div className="leaf-accens-right">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-right.png"
                    className="img-fluid"
                    alt="leaf accens right"
                  />
                </div>
          </div> */}
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-white">
                  <b>
                    LIHAT DAN RASAKAN APA YANG MEREKA BILANG TENTANG BUKU HAPPY
                    LOVE GUIDE
                  </b>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-image-colase">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/Kolase-Foto.png"
                  className="img-fluid"
                  alt="kolase foto"
                />
              </div>
            </div>
          </div>
          <div className="row mrgn-top-50">
            <div className="col-lg-12">
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="8">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/1.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/2.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/3.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/4.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="9">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/5.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/6.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/7.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/8.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/9.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/10.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/11.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/12.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="11">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/13.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/14.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/15.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/16.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="30">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="30">
                    <Card.Body>
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/17.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/18.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/19.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/20.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="31">
                      <p className="text-collapse-heading">
                        Klik untuk melihat apa yang mereka bilang...
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="31">
                    <Card.Body>
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/21.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/22.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/23.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="wrappe-images-testimoni-accordion">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/testimoni/24.jpg"
                              className="img-fluid"
                              alt="highlight hlg"
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-white">
                  <b>DAN MASIH BANYAK BANGET</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="leaf-accens-left">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-left.png"
            className="img-fluid"
            alt="leaf accens left"
          />
        </div>
        <div className="leaf-accens-right">
          <img
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/leaf-accent-right.png"
            className="img-fluid"
            alt="leaf accens right"
          />
        </div>
      </div>

      <div className="container-fluid bg-white mrgn-custome">
        <div className="container">
          {/* <div className="row">
               
              </div> */}
          <div
            className="row"
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              paddingTop: "100px",
            }}
          >
            <div className="col-lg-6">
              <div className="mockUp-women-smile d-flex justify-content-center">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/image-sec-11.png"
                  className="img-fluid"
                  alt="mockup women smile"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wrape-description-mockup-women-smile">
                <p>Happy Love Guide, buku apa tuh? </p>
                <p>Emangnya ngaruh kalo di baca??</p>
                <p>Halaah cuma teori! Mahal pula! </p>
                <p>Video di bawah, akan menjawab semua pikiran tersebut… </p>
                <p>Happy Love Guide, buku apakah ini?</p>
                <p>Isinya mudah dipraktekan ga? Atau cuma teori doang?</p>
                <p>
                  Dan mengapa{" "}
                  <span style={{ background: "yellow" }}>
                    <b>BUKU INI GAMPANG SOLD OUT meskipun “mahal”?</b>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <VideoModalBs />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="wrape-description-mockup-women-smile">
                <p className="text-center">
                  <span style={{ background: "yellow" }}>
                    <b>BURUAN! PESAN SEKARANG SEBELUM BONUSNYA HANGUS!</b>
                  </span>
                </p>
              </div>
              <div className="mrgn-bottom-100 wrappe-button-join-buy d-flex justify-content-center">
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> BELI SEKARANG
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-full-overlay-jose-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrappe-headline-top-text">
                <h2 className="text-white">
                  <b>PERCUMA CARI PASANGAN KALAU BELUM HAPPY DI DALAM..</b>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="thumbnail-girl-smiling d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/image-section-1-2.jpg"
                  alt="product hlg"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mrgn-top-100">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-50">
                  <div className="wrp-text-caption-testimoni">
                    <p>
                      Berapa banyak kamu sering dengar{" "}
                      <span style={{ backgroundColor: "yellow" }}>
                        <b>wanita yang merasa salah pilih pasangan,</b>
                      </span>{" "}
                      tersiksa batin, dan sudah berkorban habis-habisan namun
                      gak mendapat kebahagiaan dari pasangannya?
                    </p>
                    <p>
                      Berapa banyak wanita yang merasa hidupnya hampa tanpa
                      pasangan, sepi kalo gak pacaran, namun{" "}
                      <span style={{ backgroundColor: "yellow" }}>
                        <b>selalu saja bertemu pria yang memanfaatkannya?</b>
                      </span>
                    </p>
                    <p>
                      Atau..{" "}
                      <span style={{ backgroundColor: "yellow" }}>
                        <b>jangan-jangan kamu sendiri mengalaminya?</b>
                      </span>
                    </p>
                    <p>
                      Dengan keadaan seperti ini, gimana kamu bisa punya happy
                      relationship yang happy?
                    </p>
                    <p>
                      Jawabannya sederhana, kita perlu{" "}
                      <b>
                        <u>HAPPY dulu sebelum mulai hubungan.</u>
                      </b>
                    </p>
                    <p>Pertanyaannya, GIMANA SIH CARANYA SUPAYA HAPPY?</p>
                    <p>
                      Kamu boleh percaya, faktanya banyak orang masih gak paham
                      gimana caranya supaya bisa happy..
                    </p>
                    <p>
                      Gak peduli apapun pencapaian mereka, hidupnya tetap terasa
                      kosong..
                    </p>
                    <p>
                      buku ini hadir sebagai panduan untuk membawa wanita dalam
                      mewujudkan kehidupan romansa terbaiknya, mulai dari single
                      hingga bertemu pria yang tepat…
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="wrappe-button-join-buy d-flex justify-content-center"
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <AnchorLink href="#order">
                  <button type="button" className="btn btn-light">
                    <FaHandPointRight /> YA, SAYA MAU
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="wrappe-headline-top-text"
                style={{ paddingTop: "20px" }}
              >
                <h2 className="text-white">
                  <b>F A Q</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-12 mrgn-bottom-100">
              <Accordion defaultActiveKey="12">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="12">
                      <p className="text-collapse-heading text-left">
                        Bagaimana cara belinya?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="12">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Klik salah satu button pembelian dan kamu akan mengisi
                        data pembelian.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="13">
                      <p className="text-collapse-heading text-left">
                        Kapan saya bisa terima bukunya?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="13">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Pengiriman dilakukan jam 11 tiap harinya. Transaksi
                        diluar jam 11 akan dikirim di hari kerja berikutnya.
                        Sabtu & Minggu pengiriman libur.
                        <br />
                        Pengiriman dilakukan sesuai urutan transfer, itu
                        tandanya semakin cepat kamu order semakin cepat kamu
                        bisa terima bukunya ^ ^
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="14">
                      <p className="text-collapse-heading text-left">
                        Ekspedisi apa yang dipakai? Bagaimana saya melacak buku
                        saya?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Kami menggunakan ekspedisi JNE. Nomor resi akan
                        diinfokan via email.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="15">
                      <p className="text-collapse-heading text-left">
                        Kapan saya bisa terima bonus PDF Cheat Sheet, LADY
                        Skillset & Love Life Blueprint?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="15">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Bonus PDF akan diinfokan di dalam paket Buku
                        #HappyLoveGuide yang kamu terima
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="16">
                      <p className="text-collapse-heading text-left">
                        Kapan saya bisa mengakses BONUS Tambahan?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="16">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Akses BONUS tambahan ini bisa diakses via member area
                        Trulav, akan diinfokan via email setelah masa Pre Order
                        ditutup mulai 27 Februari 2023. Masa akses bonus
                        tambahan adalah 30 hari.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="17">
                      <p className="text-collapse-heading text-left">
                        Bagaimana saya tahu apakah saya mendapatkan BONUS
                        Tambahan?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="17">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Kamu akan menerima email pemberitahuan (maks 3x24jam)
                        setelah melakukan pembayaran
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="18">
                      <p className="text-collapse-heading text-left">
                        Bagaimana jika saya berada di luar negeri dan ingin
                        memesan Happy Love Guide?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="18">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Kamu bisa menghubungi tim support kami via Whatsapp
                        0878-1060-8622 (Desma)
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="19">
                      <p className="text-collapse-heading text-left">
                        Wah jadi gak sabar mau punya Buku Happy Love Guide
                        sekarang!
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="19">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Yes, saran kami. Segera putuskan beli sebelum waktu
                        habis dan kamu menyesal ketinggalan diskonnya.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="21">
                      <p className="text-collapse-heading text-left">
                        Bagaimana cara pembayarannya?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="21">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Kamu bisa pilih transfer manual atau melalui virtual
                        account
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="22">
                      <p className="text-collapse-heading text-left">
                        Masih ada pertanyaan?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="22">
                    <Card.Body>
                      <p className="text-wrappe-faq">
                        Email kami di customer@joseupmind.com atau via WhatsApp:
                        0878-1060-8622 (Desma)
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="text-center text-footer-faq">
                © {new Date().getFullYear()}, Built with Lovecoach.id
              </p>
            </div>
          </div>
        </div>
      </div>

      <a
        href="https://wa.link/hy8aso"
        target="_blank"
        rel="noreferrer"
        className="float"
      >
        <div className="my-float">
          <FaWhatsapp /> &nbsp; Butuh Bantuan?
        </div>
      </a>
    </>
  )
}
export default Special
